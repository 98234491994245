import React from 'react'
import PropTypes, { arrayOf } from 'prop-types'

import BannerFormWithEmailInput from './BannerFormWithEmailInput'

const Banner = ({ banner }) => {
  const { bannerContent, bannerFooter, bannerHeadline, image } = banner[0]

  let buttonURL = "https://kompass.carlfinance.de/bewertung/"
  if (typeof window !== "undefined") {
    var params = new URLSearchParams(window.location.search);
    params.set("page_id", window.location.href)
    buttonURL = bannerFooter[0].button[0]?.buttonUrl + "?" + params.toString()
  }
  
  return (
    <div className="mx-auto max-w-screen-xxl px-4 md:px-10 mb-8 lg:mb-15">
      <section className="flex flex-col -z-20 md:flex-row">
        <div
          className='flex flex-col bg-gradient-to-r from-black-900 to-black-500 px-4 md:px-8 lg:px-12 pb-5 z-0 md:w-2/4 md:pb-0 md:justify-center lg:w-2/4'
        >
          {/* If there is a headline, it will be rendered here on screensizes >= md */}
          {bannerHeadline.length > 0 && bannerHeadline[0].headline ? (
            <h3 className="w-full text-2xl font-bold text-white md:flex md:leading-10 mt-6 md:mb-6 xl:text-4xl xl:leading-12">
              {bannerHeadline[0].headline}
            </h3>
          ) : null}

          {/* If there is a description, it will be rendered here */}
          {bannerContent.length > 0 && bannerContent[0].description ? (
            <p className="text-17 text-white mb-6 xl:w-10/12">
              {bannerContent[0].description}
            </p>
          ) : null}        

          {/* If there is a quote, it will be rendered here */}
          {bannerContent.length > 0 && bannerContent[0].citation?.length > 0 ? (
            <div className="flex flex-col text-white mb-5 lg:w-3/4 xl:w-3/5">
              <p className="italic text-17 text-white mb-1 md:text-xl">
                "{bannerContent[0].citation[0].quoteText}"
              </p>
              <div className="self-end">
                <p className="text-13 text-white">
                  -{bannerContent[0].citation[0].quoteAuthor}
                </p>
                <p className="text-13 text-white">
                  {bannerContent[0].citation[0].quoteAuthorPosition}
                </p>
              </div>
            </div>
          ) : null}

          {/* Checks if there is an email form. If there is one, it will be rendered.
          If not, it will check whether there is a button. If there is,
          the button will be rendered. If there is neither an email form, nor
          a button, null will be returned */}

          {bannerFooter.length > 0 ? (
            bannerFooter[0].showEmailForm ? (
              <div className="mb-5 mt-4">
                {bannerFooter[0].uniformResourceLocator ? (
                  <BannerFormWithEmailInput wide url={bannerFooter[0].uniformResourceLocator} />
                ) : (
                  <BannerFormWithEmailInput wide url="https://hooks.zapier.com/hooks/catch/2517134/oki9jjl" />
                )}
              </div>
            ) : bannerFooter[0].button.length > 0 ? (
              <a
                href={bannerFooter[0].button[0].buttonUrl}
                className="button-primary-white inline-block mb-2 md:mb-5 mt-4"
              >
                {bannerFooter[0].button[0].buttonText}
              </a>
            ) : null
          ) : null}
        </div>
      

        <div className="hidden md:flex md:w-2/4 ">
          <img src={image[0].url} className="object-center object-cover" alt="" loading="lazy" />
        </div>
          
      </section>
    </div>
  )
}

Banner.propTypes = {
  banner: PropTypes.arrayOf(
    PropTypes.shape({
      image: arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired
        })
      ).isRequired,
      bannerHeadline: PropTypes.arrayOf(
        PropTypes.shape({
          headline: PropTypes.string,
          subHeadline: PropTypes.string
        })
      ),
      bannerContent: PropTypes.arrayOf(
        PropTypes.shape({
          list: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              text: PropTypes.string
            })
          ),
          description: PropTypes.string,
          citation: PropTypes.arrayOf(
            PropTypes.shape({
              quoteAuthor: PropTypes.string,
              quoteAuthorPosition: PropTypes.string,
              quoteText: PropTypes.string
            })
          )
        })
      ),
      bannerFooter: PropTypes.arrayOf(
        PropTypes.shape({
          showEmailForm: PropTypes.bool.isRequired,
          button: PropTypes.arrayOf(
            PropTypes.shape({
              buttonText: PropTypes.string,
              buttonUrl: PropTypes.string
            })
          )
        })
      )
    })
  )
}

export default Banner
