import React from 'react'

import ArticleGrid from '../components/ArticleGrid'
import Banner from '../components/Banner'
import ContentBody from '../components/ContentBody'
import ContentHeaderBgImg from '../components/ContentHeaderBgImg'
import Layout from '../components/layout'
import SEO from '../components/seo'
import TopArticleRow from '../components/TopArticleRow'

export default ({ pageContext }) => {
  const {
    articleGrids,
    description,
    headline,
    featured,
    seomatic,
    thumbnailSmall,
    thumbnailMedium,
    thumbnailLarge,
    selectedBanner,
    title
  } = pageContext

  const thumbnails = {
    thumbnailSmall: (thumbnailSmall.length ? thumbnailSmall[0].url : null),
    thumbnailMedium: (thumbnailMedium.length ? thumbnailMedium[0].url : null),
    thumbnailLarge: (thumbnailLarge.length ? thumbnailLarge[0].url : null)
  }

  return (
    <Layout>
      <SEO seomatic={seomatic} />
      <ContentHeaderBgImg title={title} text={headline} meta={description} thumbnails={thumbnails}/>
      <ContentBody>
        <TopArticleRow
          topArticle={featured[0].topArticle[0]}
          showRatingService={featured[0].showRatingService}
          showSellService={featured[0].showSellService}
          showTaxService={featured[0].showTaxService}
          news={featured[0].newsSet}
        />
        <ArticleGrid articleRows={articleGrids} />
      </ContentBody>
      {selectedBanner.length ? 
          <Banner banner={selectedBanner} />
          : null}
    </Layout>
  )
}
